<template>
  <ion-page>
    <Header></Header>
    <ion-content :fullscreen="true">

       <section class="md-section" id="legal-text">
        <div class="container">
          <h1 class="text-center" style="padding-bottom: 1rem"><strong>Condiciones de Compra</strong></h1>

          <div class="invisible-box">
            <h3>Información General</h3>
            <p>La titularidad de este sitio web novoinox.com, (en adelante Sitio Web) la ostenta: INOXIDABLES CHICLANA S.L., con CIF: B-72035678, y cuyos datos de contacto son:</p>
            <p>
              <ul>
                <li>Dirección: Calle Marie Curie con Esq., Calle Severo Ochoa, 11130 Chiclana de la Frontera, Cádiz</li>
                <li>Teléfono de contacto: 956 53 44 41</li>
                <li>Email de contacto: info@novoinox.com</li>
              </ul>
            </p>

            <p>Este documento (así como otros documentos que aquí se mencionen) regula las condiciones por las que se rige el uso de este Sitio Web (novoinox.com) y la compra o adquisición de productos y/o servicios en el mismo (en adelante, Condiciones).</p>
            <p>A efectos de estas Condiciones se entiende que la actividad que Novoinox desarrolla a través del Sitio Web comprende:</p>
            <p>Comercialización, distribución y fabricación de PRODUCTOS DE ACERO INOXIDABLE</p>
            <p>Además de leer las presentes Condiciones, antes de acceder, navegar y/o usar esta página web, el Usuario ha de haber leído el Aviso Legal y las Condiciones Generales de Uso, incluyendo, la política de cookies, y la política de privacidad y de protección de datos de Novoinox. Al utilizar este Sitio Web o al hacer y/o solicitar la adquisición de un producto y/o servicio a través del mismo el Usuario consiente quedar vinculado por estas Condiciones y por todo lo anteriormente mencionado, por lo que si no está de acuerdo con todo ello, no debe usar este Sitio Web.</p>
            <p>Asimismo, se informa que estas Condiciones podrían ser modificadas. El Usuario es responsable de consultarlas cada vez que acceda, navegue y/o use el Sitio Web ya que serán aplicables aquellas que se encuentren vigentes en el momento en que se solicite la adquisición de productos y/o servicios.</p>
            <p>Para todas las preguntas que el Usuario pueda tener en relación con las Condiciones puede ponerse en contacto con el titular utilizando los datos de contacto facilitados más arriba o, en su caso, utilizando el formulario de contacto.</p>
          </div>

          <div class="invisible-box">
            <h3>El usuario</h3>

            <p>El acceso, la navegación y uso del Sitio Web, confiere la condición de usuario (en adelante referido, indistintamente, individualmente como Usuario o conjuntamente como Usuarios), por lo que se aceptan, desde que se inicia la navegación por el Sitio Web, todas las Condiciones aquí establecidas, así como sus ulteriores modificaciones, sin perjuicio de la aplicación de la correspondiente normativa legal de obligado cumplimiento según el caso.</p>
            <p>El Usuario asume su responsabilidad de un uso correcto del Sitio Web. Esta responsabilidad se extenderá a:</p>
            <p>
              <ul>
                <li>Hacer uso de este Sitio Web únicamente para realizar consultas y compras o adquisiciones legalmente válidas.</li>
                <li>No realizar ninguna compra falsa o fraudulenta. Si razonablemente se pudiera considerar que se ha hecho una compra de esta índole, podría ser anulada y se informaría a las autoridades pertinentes.</li>
                <li>Facilitar datos de contacto veraces y lícitos, por ejemplo, dirección de correo electrónico, dirección postal y/u otros datos (ver Aviso Legal y Condiciones Generales de Uso).</li>
              </ul>
            </p>
            <p>El Usuario declara ser mayor de 18 años y tener capacidad legal para celebrar contratos a través de este Sitio Web.</p>
            <p>El Sitio Web está dirigido principalmente a Usuarios residentes en España. Novoinox no asegura que el Sitio Web cumpla con legislaciones de otros países, ya sea total o parcialmente. Novoinox declina toda responsabilidad que se pueda derivar de dicho acceso, así como tampoco asegura envíos o prestación de servicios fuera de España.</p>
            <p>El Usuario podrá formalizar, a su elección, con Novoinox el contrato de compraventa de los productos y/o servicios deseados en cualquiera de los idiomas en los que las presentes Condiciones estén disponibles en este Sitio Web.</p>
          </div>

          <div class="invisible-box">
            <h3>Proceso de Compra o Adquisición</h3>

            <p>Los Usuarios debidamente registrados pueden comprar en el Sitio Web por los medios y formas establecidos. Deberán seguir el procedimiento de compra y/o adquisición online de novoinox.com, durante el cual varios productos y/o servicios pueden ser seleccionados y añadidos al carrito, cesta o espacio final de compra y, finalmente, hacer clic en: "Finalizar Pedido"</p>
            <p>Asimismo, el Usuario deberá rellenar y/o comprobar la información que en cada paso se le solicita, aunque, durante el proceso de compra, antes de realizar el pago, se pueden modificar los datos de la compra.</p>
            <p>
            Una vez el procedimiento de compra ha concluido, el Usuario consiente que el Sitio Web genere una factura electrónica que se hará llegar al Usuario a través del correo electrónico y en su caso, a través de su espacio personal de conexión al Sitio Web. Asimismo, el Usuario puede, si así lo desea, obtener una copia de su factura en papel, solicitándolo a Novoinox utilizando los espacios de contacto del Sitio Web o a través de los datos de contacto facilitados más arriba.</p>
            <p>El Usuario reconoce estar al corriente, en el momento de la compra, de ciertas condiciones particulares de venta que conciernen al producto y/o servicio en cuestión y que se muestran junto a la presentación o, en su caso, imagen de éste en su página del Sitio Web, indicando, a modo enunciativo, pero no exhaustivo, y atendiendo a cada caso: nombre, precio, componentes, peso, cantidad, color, detalles de los productos, o características, modo en el que se llevarán a cabo y/o coste de las prestaciones; y reconoce que la realización del pedido de compra o adquisición materializa la aceptación plena y completa de las condiciones particulares de venta aplicables a cada caso.</p>
            <p>Las comunicaciones, órdenes de compra y pagos que intervengan durante las transacciones efectuadas en el Sitio Web podrían ser archivadas y conservadas en los registros informatizados de Novoinox con el fin de constituir un medio de prueba de las transacciones, en todo caso, respetando las condiciones razonables de seguridad y las leyes y normativas vigentes que a este respecto sean de aplicación, y particularmente atendiendo al Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD) y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, y a los derechos que asisten a los Usuarios conforme a la política de privacidad de este Sitio Web.</p>
          </div>

          <div class="invisible-box">
            <h3>Disponibilidad</h3>

            <p>Todos los pedidos de compra recibidos por Novoinox a través del Sitio Web están sujetos a la disponibilidad de los productos y/o a que ninguna circunstancia o causa de fuerza mayor (cláusula nueve de estas Condiciones) afecte al suministro de los mismos y/o a la prestación de los servicios. Si se produjeran dificultades en cuanto al suministro de productos o no quedaran productos en stock, Novoinox se compromete a contactar al Usuario y reembolsar cualquier cantidad que pudiera haber sido abonada en concepto de importe. Esto será igualmente aplicable en los casos en los que la prestación de un servicio deviniera irrealizable.</p>
          </div>

          <div class="invisible-box">
            <h3>Precios y Pago</h3>

            <p>Los precios exhibidos en el Sitio Web son los finales, en Euros (€) e incluyen los impuestos, salvo que por exigencia legal, especialmente en lo relativo al IVA, se señale y aplique cuestión distinta.</p>
            <p>Los gastos de envío se encuentran incluidos en los precios finales de los productos tal y como se muestran en el Sitio Web. Así, Novoinox realiza los servicios de entrega y/o envío a través de: Correos, Mondial Relay.</p>
            <p>En ningún caso el Sitio Web añadirá costes adicionales al precio de un producto o de un servicio de forma automática, sino solo aquellos que el Usuario haya seleccionado y elegido voluntaria y libremente.</p>
            <p>Los precios pueden cambiar en cualquier momento, pero los posibles cambios no afectarán a los pedidos o compras con respecto a los que el Usuario ya haya finalizado el proceso de solicitud de compra en el Sitio Web tal y como se indica en el párrafo uno de la cláusula tres.</p>
            <p>Los medios de pago aceptados serán: Tarjeta de crédito o débito</p>
            <p>Novoinox utiliza todos los medios para garantizar la confidencialidad y la seguridad de los datos de pago transmitidos por el Usuario durante las transacciones a través del Sitio Web. Como tal, el Sitio Web utiliza un sistema de pago seguro SSL (Secure Socket Layer).</p>
            <p>Las tarjetas de crédito estarán sujetas a comprobaciones y autorizaciones por parte de la entidad bancaria emisora de las mismas, si dicha entidad no autorizase el pago, Novoinox no será responsable por ningún retraso o falta de entrega y no podrá formalizar ningún contrato con el Usuario.</p>
            <p>Una vez que Novoinox reciba la orden de compra por parte del Usuario a través del Sitio Web, se hará una pre-autorización en la tarjeta que corresponda para asegurar que existen fondos suficientes para completar la transacción. El cargo en la tarjeta se hará en el momento en que se envíe al Usuario la confirmación de envío y/o confirmación del servicio que se presta en forma y, en su caso, lugar establecidos.</p>
            <p>En todo caso, al hacer clic en "Finalizar Compra" el Usuario confirma que el método de pago utilizado es suyo.</p>
          </div>

          <div class="invisible-box">
            <h3>Entrega</h3>

            <p>En los casos en los que proceda realizar la entrega física del bien contratado, las entregas se efectuarán en el ámbito del siguiente territorio: España (Península y Baleares)</p>
            <p>Exceptuando aquellos casos en los que existan circunstancias imprevistas o extraordinarias o, en su caso, derivadas de la personalización de los productos, el pedido de compra consistente en los productos relacionados en cada confirmación de compra será entregado en el plazo señalado en el Sitio Web según el método de envío seleccionado por el Usuario y, en todo caso, en el plazo máximo de 30 días naturales a contar desde la fecha de la confirmación del pedido.</p>
            <p>Si por algún motivo, que le fuera imputable, Novoinox no pudiera cumplir con la fecha de entrega, contactará al Usuario para informarle de esta circunstancia y, éste podrá elegir seguir adelante con la compra estableciendo una nueva fecha de entrega o bien anular el pedido con el reembolso total del precio pagado. En cualquier caso, las entregas a domicilio se realizan en días laborables.</p>
            <p>Si resultara imposible efectuar la entrega del pedido por ausencia del Usuario, el pedido podría ser devuelto al almacén. No obstante, el transportista dejaría un aviso explicando dónde se encuentra el pedido y cómo hacer para que sea entregado de nuevo.</p>
            <p>Si el Usuario no va a estar en el lugar de entrega en la franja horaria convenida, debe ponerse en contacto con Novoinox para convenir la entrega otro día.</p>
            <p>En caso de que transcurran 30 días desde que su pedido esté disponible para su entrega, y no haya sido entregado por causa no imputable a Novoinox, Novoinox entenderá que el Usuario desea desistir del contrato y éste se considerará resuelto. Como consecuencia de la resolución del contrato, todos los pagos recibidos del Usuario le serán devueltos, a excepción de los gastos adicionales resultantes de la elección propia del Usuario de una modalidad de entrega diferente a la modalidad menos costosa de entrega ordinaria que ofrece el Sitio Web, sin ninguna demora indebida y, en cualquier caso, en el plazo máximo de 14 días naturales desde la fecha en que se considera resuelto el contrato.</p>
            <p>No obstante, el Usuario debe tener presente que el transporte derivado de la resolución puede tener un coste adicional que le podrá ser repercutido.</p>
            <p>A efectos de las presentes Condiciones, se entenderá que se ha producido la entrega o que el pedido ha sido entregado en el momento en el que el Usuario o un tercero indicado por el Usuario adquiera la posesión material de los productos, lo que se acreditará mediante la firma de la recepción del pedido en la dirección de entrega convenida.</p>
            <p>Los riesgos que de los productos se pudieran derivar serán a cargo del Usuario a partir del momento de su entrega. El Usuario adquiere la propiedad de los productos cuando Novoinox recibe el pago completo de todas las cantidades debidas en relación a la compra o adquisición efectuada, incluidos los gastos de envío, o bien en el momento de la entrega, si ésta tiene lugar en un momento posterior a la recepción completa del importe objeto de pago por Novoinox.</p>
            <p>De conformidad con lo dispuesto en la Ley 37/1992, de 28 de diciembre, del Impuesto sobre el Valor Añadido (IVA), los pedidos de compra para su entrega y/o prestación se entenderán localizados en el territorio de aplicación del IVA español si la dirección de entrega está en territorio español salvo Canarias, Ceuta y Melilla. El tipo de IVA aplicable será el legalmente vigente en cada momento en función del artículo concreto de que se trate.</p>
          </div>

          <div class="invisible-box">
            <h3>Medios Técnicos para corregir errores</h3>

            <p>Se pone en conocimiento del Usuario que en caso de que detecte que se ha producido un error al introducir datos necesarios para procesar su solicitud de compra en el Sitio Web, podrá modificar los mismos poniéndose en contacto con Novoinox a través de los espacios de contacto habilitados en el Sitio Web, y, en su caso, a través de aquellos habilitados para contactar con el servicio de atención al cliente, y/o utilizando los datos de contacto facilitados en la cláusula primera (Información general). Asimismo, estas informaciones también podrían subsanarse por el Usuario a través de su espacio personal de conexión al Sitio Web.</p>
            <p>En cualquier caso, el Usuario, antes de hacer clic en "Finalizar Compra", tiene acceso al espacio, carrito, o cesta donde se van anotando sus solicitudes de compra y puede hacer modificaciones.</p>
            <p>De igual forma, se remite al Usuario a consultar el Aviso Legal y Condiciones Generales de Uso y, en concreto, la Política de Privacidad para recabar más información sobre cómo ejercer su derecho de rectificación según lo establecido en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD) y en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales.</p>
          </div>

          <div class="invisible-box">
            <h3>Devoluciones</h3>
            <p>En los casos en los que el Usuario adquiriera productos en o través del Sitio Web del titular, le asisten una serie de derechos, tal y como se enumeran y describen a continuación:</p>
            
            <h5>Derecho de Desistimiento</h5>
            <p>El Usuario, en tanto que consumidor y usuario, realiza una compra en el Sitio Web y, por tanto le asiste el derecho a desistir de dicha compra en un plazo de 14 días naturales sin necesidad de justificación.</p>
            <p>Este plazo de desistimiento expirará a los 14 días naturales del día que el Usuario o un tercero autorizado por éste, distinto del transportista, adquirió la posesión material de los bienes adquiridos en el Sitio Web de Novoinox o en caso de que los bienes que componen su pedido se entreguen por separado, a los 14 días naturales del día que el Usuario o un tercero autorizado por éste, distinto del transportista, adquirió la posesión material del último de esos bienes que componían un mismo pedido de compra, o en el caso de tratarse de un contrato de servicios, a los 14 días naturales desde el día de la celebración del contrato.</p>
            <p>Para ejercer este derecho de desistimiento, el Usuario deberá notificar su decisión a Novoinox. Podrá hacerlo, en su caso, a través de los espacios de contacto habilitados en el Sitio Web.</p>
            <p>El Usuario, independientemente del medio que elija para comunicar su decisión, debe expresar de forma clara e inequívoca que es su intención desistir del contrato de compra. En todo caso, el Usuario podrá utilizar el modelo de formulario de desistimiento que Novoinox pone a su disposición como parte anexada a estas Condiciones, sin embargo, su uso no es obligatorio.</p>
            <p>Para cumplir el plazo de desistimiento, basta con que la comunicación que expresa inequívocamente la decisión de desistir sea enviada antes de que venza el plazo correspondiente.</p>
            <p>En caso de desistimiento, Novoinox reembolsará al Usuario todos los pagos recibidos, incluidos los gastos de envío (con la excepción de los gastos adicionales elegidos por el Usuario para una modalidad de envío diferente a la modalidad menos costosa ofrecida en el Sitio Web) sin ninguna demora indebida y, en todo caso, a más tardar en 14 días naturales a partir de la fecha en la que Novoinox es informado de la decisión de desistir por el Usuario.</p>
            <p>Novoinox reembolsará al Usuario utilizando el mismo método de pago que empleó este para realizar la transacción inicial de compra. Este reembolso no generará ningún coste adicional al Usuario. No obstante, Novoinox podría retener dicho reembolso hasta haber recibido los productos o artículos de la compra, o hasta que el Usuario presente una prueba de la devolución de los mismos, según qué condición se cumpla primero.</p>
            <p>El Usuario puede devolver o enviar los productos a Novoinox en: Calle Antonio López No30B, 11100 San Fernando (Cádiz)</p>
            <p>Y deberá hacerlo sin ninguna demora indebida y, en cualquier caso, a más tardar en el plazo de 14 días naturales a partir de la fecha en que Novoinox fue informado de la decisión de desistimiento.</p>
            <p>El Usuario reconoce conocer que deberá asumir el coste directo de devolución (transporte, entrega) de los bienes, si se incurriera en alguno. Además, será responsable de la disminución de valor de los productos resultante de una manipulación distinta a la necesaria para establecer la naturaleza, las características y el funcionamiento de los bienes.</p>
            <p>El Usuario reconoce saber que existen excepciones al derecho de desistimiento, tal y como se recoge en el artículo 103 del Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias. De forma enunciativa, y no exhaustiva, este sería el caso de: productos personalizados; productos que puedan deteriorarse o caducar con rapidez; CDs/DVD de música o video sin su envoltorio, tal y como se precinta en fábrica; productos que por razones de higiene o de la salud van precintados y han sido desprecintados tras la entrega.</p>
            <p>En este mismo sentido se rige la prestación de un servicio que el Usuario pudiera contratar en este Sitio Web, pues esta misma Ley establece que no asistirá el Derecho de desistimiento a los Usuarios cuando la prestación del servicio ha sido completamente ejecutada, o cuando haya comenzado, con el consentimiento expreso del consumidor y usuario y con el reconocimiento por su parte de que es consciente de que, una vez que el contrato haya sido completamente ejecutado por Novoinox, habrá perdido su derecho de desistimiento.</p>
            <p>En todo caso, no se hará ningún reembolso si el producto ha sido usado más allá de la mera apertura del mismo, de productos que no estén en las mismas condiciones en las que se entregaron o que hayan sufrido algún daño tras la entrega.</p>
            <p>Asimismo, se debe devolver los productos usando o incluyendo todos sus envoltorios originales, las instrucciones y demás documentos que en su caso los acompañen, además de una copia de la factura de compra.</p>
            <p>En el siguiente enlace puede descargarse el Modelo de formulario de desistimiento: <a href="https://novoinox.com/returns">https://novoinox.com/returns</a></p>

            <h5>Devolución de productos defectuosos o error en el envío</h5>
            <p>Se trata de todos aquellos casos en los que el Usuario considera que, en el momento de la entrega, el producto no se ajusta a lo estipulado en el contrato o pedido de compra, y que, por tanto, deberá ponerse en contacto con Novoinox inmediatamente y hacerle saber la disconformidad existente (defecto/error) por los mismos medios o utilizando los datos de contacto que se facilitan en el apartado anterior (Derecho de Desistimiento).</p>
            <p>El Usuario será entonces informado sobre cómo proceder a la devolución de los productos, y estos, una vez devueltos, serán examinados y se informará al Usuario, dentro de un plazo razonable, si procede el reembolso o, en su caso, la sustitución del mismo.</p>
            <p>El reembolso o la sustitución del producto se efectuará lo antes posible y, en cualquier caso, dentro de los 14 días siguientes a la fecha en la que le enviemos un correo electrónico confirmando que procede el reembolso o la sustitución del artículo no conforme.</p>
            <p>El importe abonado por aquellos productos que sean devueltos a causa de algún defecto, cuando realmente exista, será reembolsado íntegramente, incluidos los gastos de entrega y los costes en que hubiera podido incurrir el Usuario para realizar la devolución. El reembolso se efectuará por el mismo medio de pago que el Usuario utilizó para pagar la compra.</p>
            <p>En todo caso, se estará siempre a los derechos reconocidos en la legislación vigente en cada momento para el Usuario, en tanto que consumidor y usuario.</p>

            <h5>Garantías</h5>
            <p>El Usuario, en tanto que consumidor y usuario, goza de garantías sobre los productos que pueda adquirir a través de este Sitio Web, en los términos legalmente establecidos para cada tipo de producto, respondiendo Novoinox, por tanto, por la falta de conformidad de los mismos que se manifieste en un plazo de dos años desde la entrega del producto.</p>
            <p>En este sentido, se entiende que los productos son conformes con el contrato siempre que: se ajusten a la descripción realizada por Novoinox y posean las cualidades presentadas en la misma; sean aptos para los usos a que ordinariamente se destinan los productos del mismo tipo; y presenten la calidad y prestaciones habituales de un producto del mismo tipo y que sean fundamentalmente esperables del mismo. Cuando esto no sea así respecto de los productos entregados al Usuario, éste deberá proceder tal y como se indica en el apartado Devolución de productos defectuosos o error en el envío. No obstante, algunos de los productos que se comercializan en el Sitio Web, podrían presentar características no homogéneas siempre y cuando éstas deriven del tipo de material con el que se han fabricado, y que por ende formarán parte de la apariencia individual del producto, y no serán un defecto.</p>
            <p>Por otra parte, podría llegar a darse el caso que el Usuario adquiere en el Sitio Web un producto de una marca o de fabricación por un tercero. En este caso, y considerando el Usuario que se trata de un producto defectuoso, éste también tiene la posibilidad de ponerse en contacto con la marca o fabricante responsable del producto para averiguar cómo ejercer su derecho de garantía legal directamente frente a los mismos durante los dos años siguientes a la entrega de dichos productos. Para ello, el Usuario debe haber conservado toda la información en relación con la garantía de los productos.</p>
          </div>

          <div class="invisible-box">
            <h3>Exoneración de Responsabilidad</h3>

            <p>Salvo disposición legal en sentido contrario, Novoinox no aceptará ninguna responsabilidad por las siguientes pérdidas, con independencia de su origen:</p>
            <p>
              <ul>
                <li>cualesquiera pérdidas que no fueran atribuibles a incumplimiento alguno por su parte;</li>
                <li>pérdidas empresariales (incluyendo lucro cesante, de ingresos, de contratos, de ahorros previstos, de datos, pérdida del fondo de comercio o gastos innecesarios incurridos); o de</li>
                <li>toda otra pérdida indirecta que no fuera razonablemente previsible por ambas partes en el momento en que se formalizó el contrato de compraventa de los productos entre ambas partes.</li>
              </ul>
            </p>

            <p>Igualmente, Novoinox también limita su responsabilidad en cuanto a los siguientes casos:</p>
            <p>
              <ul>
                <li>Novoinox aplica todas las medidas concernientes a proporcionar una visualización fiel del producto en el Sitio Web, sin embargo no se responsabiliza por las mínimas diferencias o inexactitudes que puedan existir debido a falta de resolución de la pantalla, o problemas del navegador que se utilice u otros de esta índole.</li>
                <li>Novoinox actuará con la máxima diligencia a efectos de poner a disposición de la empresa encargada del transporte del producto objeto del pedido de compra. Sin embargo, no se responsabiliza por perjuicios provenientes de un mal funcionamiento del transporte, especialmente por causas como huelgas, retenciones en carreteras, y en general cualquiera otras propias del sector, que deriven en retrasos, pérdidas o hurtos del producto.</li>
                <li>Fallos técnicos que por causas fortuitas o de otra índole, impidan un normal funcionamiento del servicio a través de internet. Falta de disponibilidad del Sitio Web por razones de mantenimiento u otras, que impida disponer del servicio. Novoinox pone todos los medios a su alcance a efectos de llevar a cabo el proceso de compra, pago y envío/entrega de los productos, no obstante se exime de responsabilidad por causas que no le sean imputables, caso fortuito o fuerza mayor.</li>
                <li>Novoinox no se hará responsable del mal uso y/o del desgaste de los productos que hayan sido utilizados por el Usuario. Al mismo tiempo, Novoinox tampoco se hará responsable de una devolución errónea realizada por el Usuario. Es responsabilidad del Usuario devolver el producto correcto.</li>
                <li>En general, Novoinox no se responsabilizará por ningún incumplimiento o retraso en el cumplimiento de alguna de las obligaciones asumidas, cuando el mismo se deba a acontecimientos que están fuera de su control razonable, es decir, que se deban a causa de fuerza mayor, y ésta podrá incluir, a modo enunciativo pero no exhaustivo:
                  <ul>
                    <li>Huelgas, cierres patronales u otras medidas reivindicativas.</li>
                    <li>Conmoción civil, revuelta, invasión, amenaza o ataque terrorista, guerra (declarada o no) o amenaza o preparativos de guerra.</li>
                    <li>Incendio, explosión, tormenta, inundación, terremoto, hundimiento, epidemia o cualquier otro desastre natural.</li>
                    <li>Imposibilidad de uso de trenes, barcos, aviones, transportes de motor u otros medios de transporte, públicos o privados.</li>
                    <li>Imposibilidad de utilizar sistemas públicos o privados de telecomunicaciones.</li>
                    <li>Actos, decretos, legislación, normativa o restricciones de cualquier gobierno o autoridad pública.</li>
                  </ul>
                </li>
              </ul>
            </p>
            <p>De esta forma, las obligaciones quedarán suspendidas durante el periodo en que la causa de fuerza mayor continúe, y Novoinox dispondrá de una ampliación en el plazo para cumplirlas por un periodo de tiempo igual al que dure la causa de fuerza mayor. Novoinox pondrá todos los medios razonables para encontrar una solución que le permita cumplir con sus obligaciones a pesar de la causa de fuerza mayor.</p>
          </div>


          <div class="invisible-box">
            <h3>Comunicaciones por Escrito y Notificaciones</h3>

            <p>Mediante el uso de este Sitio Web, el Usuario acepta que la mayor parte de las comunicaciones con Novoinox sean electrónicas (correo electrónico o avisos publicados en el Sitio Web).</p>
            <p>A efectos contractuales, el Usuario consiente en usar este medio electrónico de comunicación y reconoce que todo contrato, notificación, información y demás comunicaciones que Novoinox envíe de forma electrónica cumplen con los requisitos legales de ser por escrito. Esta condición no afectará a los derechos reconocidos por ley al Usuario.</p>
            <p>El Usuario puede enviar notificaciones y/o comunicarse con Novoinox a través de los datos de contacto que en estas Condiciones se facilitan y, en su caso, a través de los espacios de contacto del Sitio Web.</p>
            <p>Igualmente, salvo que se estipule lo contrario, Novoinox puede contactar y/o notificar al Usuario en su correo electrónico o en la dirección postal facilitada.</p>
          </div>

          <div class="invisible-box">
            <h3>Renuncia</h3>

            <p>Ninguna renuncia de Novoinox a un derecho o acción legal concreta o la falta de requerimiento por Novoinox del cumplimiento estricto por el Usuario de alguna de sus obligaciones supondrá, ni una renuncia a otros derechos o acciones derivados de un contrato o de las Condiciones, ni exonerará al Usuario del cumplimiento de sus obligaciones.</p>
            <p>Ninguna renuncia de Novoinox a alguna de las presentes Condiciones o a los derechos o acciones derivados de un contrato surtirá efecto, a no ser que se establezca expresamente que es una renuncia y se formalice y se le comunique al Usuario por escrito.</p>
          </div>

          <div class="invisible-box">
            <h3>Nulidad</h3>
            <p>Si alguna de las presentes Condiciones fuesen declaradas nulas y sin efecto por resolución firme dictada por autoridad competente, el resto de las cláusulas permanecerán en vigor, sin que queden afectadas por dicha declaración de nulidad.</p>
          </div>

          <div class="invisible-box">
            <h3>Acuerdo Completo</h3>
            <p>Las presentes Condiciones y todo documento al que se haga referencia expresa en estas constituyen el acuerdo íntegro existente entre el Usuario y Novoinox en relación con el objeto de compraventa y sustituyen a cualquier otro pacto, acuerdo o promesa anterior convenida verbalmente o por escrito por las mismas partes.</p>
            <p>El Usuario y Novoinox reconocen haber consentido la celebración de un contrato sin haber confiado en ninguna declaración o promesa hecha por la otra parte, salvo aquello que figura expresamente mencionado en las presentes Condiciones.</p>
          </div>

          <div class="invisible-box">
            <h3>Protección de Datos</h3>
            <p>La información o datos de carácter personal que el Usuario facilite a Novoinox en el curso de una transacción en el Sitio Web, serán tratados con arreglo a lo establecido en la Política de Privacidad o de protección de datos (contenida, en su caso, en el Aviso Legal y Condiciones Generales de Uso). Al acceder, navegar y/o usar el Sitio Web el Usuario consiente el tratamiento de dicha información y datos y declara que toda la información o datos que facilita son veraces.</p>
          </div>

          <div class="invisible-box">
            <h3>Legislación Aplicable y Jurisdicción</h3>

            <p>El acceso, navegación y/o uso de este Sitio Web y los contratos de compra de productos a través del mismo se regirán por la legislación española.</p>
            <p>Cualquier controversia, problema o desacuerdo que surja o este relacionado con el acceso, navegación y/o uso del Sitio Web, o con la interpretación y ejecución de estas Condiciones, o con los contratos de venta entre Novoinox y el Usuario, será sometida a la jurisdicción no exclusiva de los juzgados y tribunales españoles.</p>
          </div>

          <div class="invisible-box">
            <h3>Quejas y Reclamaciones</h3>
            
            <p>El Usuario puede hacer llegar a Novoinox sus quejas, reclamaciones o todo otro comentario que desee realizar a través de los datos de contacto que se facilitan al principio de estas Condiciones (Información General).</p>
            <p>Además, Novoinox dispone de hojas oficiales de reclamación a disposición de los consumidores y usuarios, y que estos pueden solicitar a Novoinox en cualquier momento, utilizando los datos de contacto que se facilitan al principio de estas Condiciones (Información General).</p>
            <p>Asimismo, si de la celebración de este contrato de compra entre Novoinox y el Usuario emanara una controversia, el Usuario como consumidor puede solicitar una solución extrajudicial de controversias, de acuerdo con el Reglamento (UE) 524/2013 del Parlamento Europeo y del Consejo, de 21 de mayo de 2013, sobre resolución de litigios en línea en materia de consumo y por el que se modifica el Reglamento (CE) 2006/2004 y la Directiva 2009/22/CE. Puede acceder a este método a través del siguiente sitio web: <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>.</p>
          </div>          
        </div>
      </section>

      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  name: 'PurchaseConditions',
  components: {
    IonContent,
    IonPage,
    Header,
    Footer,
  }
});
</script>

<style scoped>

</style>